$(document).ready(function () {
	var smController = new ScrollMagic.Controller();
	//---- pin parallax
	$(".pin-in-position").each(function () {
		console.log('yes');

		var pin = new ScrollMagic.Scene({
			triggerElement: $(this).parent(),
			triggerHook: 0,
			duration: '200%'
		})
			// .addIndicators()
			.setTween(TweenMax.to(this, 1, { y: '100%', ease: Power0.easeNone }))
			.addTo(smController);

	});

	var playerIdle = null;

	$(".hero-banner .vid-control-btn")
		.click(function (e) {
			e.stopPropagation();
			e.preventDefault();

			var videoContainer = $(this).parents('.video-banner');
			var videoId = videoContainer.find('iframe').attr('id');
			var action = $(this).data("action");

			if (action === "play") {
				if (playVideo(videoId)) {
					videoContainer.addClass("playing played");
					playerIdle = setTimeout(function () {
						videoContainer.addClass("idle");
					}, 1500);
				}
			} else {
				clearTimeout(playerIdle);
				videoContainer.removeClass("playing idle");
				pauseVideo(videoId);
			}

			$(this).addClass("active");
			$(this).siblings(".vid-control-btn").removeClass("active");
		})
		.mousedown(function (e) {
			e.stopPropagation();
			e.preventDefault();
		});

	$(document).on("mousemove", ".video-banner", function () {
		var _this = $(this);
		if (_this.hasClass("playing")) {
			_this.removeClass("idle");
			clearTimeout(playerIdle);
			playerIdle = setTimeout(function () {
				_this.addClass("idle");
			}, 1500);
		}
	});

	function playVideo(id) {
		if (videos[id] !== undefined) {
			try {
				videos[id].playVideo();
				return true;
			} catch (err) {
				return false;
			}
		} else {
			return false;
		}
	}

	function pauseVideo(id) {
		videos[id].pauseVideo();
	}

	function slideChangeCallback() {
		var slide = $(".slide.active");
		if (slide.find('.video-banner').length > 0) {
			slide.find('.pause-btn').click();
		}
	}
});

//---- load YOUTUBE VIDEOS
var videos = {};
function onYouTubeIframeAPIReady() {
	$('.video-iframe').each(function () {
		var id = $(this).attr('id');

		if (id) {
			videos[id] = getYTVideo(id);
			// console.log(videos[id]);
		}
	});
}
//---- get and create iframe for youtube video
function getYTVideo(id) {
	var player;
	player = new YT.Player(id, {
		height: '1080',
		width: '1920',
		videoId: id,
		playerVars: {
			modestbranding: 1,
			controls: 0,
			showinfo: 0,
			rel: 0,
			autoplay: 0,
			loop: 0
		},
		events: {
			'onStateChange': onVideoStateChange
		}
	});

	return player;
}

function onVideoStateChange(e) {
	//---- video ended
	if (e.data === 0) {
		videoStopped(e.target.a.id);
	}
}

function videoStopped(videoId) {
	var videoContainer = $("#" + videoId).parents('.video-banner');
	videoContainer.removeClass("playing played idle");
}
